@import "../../styles//variables.scss";
.AboutPageWeb {
    .skillsFlex {
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-radius: $borderRadius;
      flex-wrap: wrap;
      font-size: 28px;
      background-color: #B6E2D3;
      color: black;
      // border: black 4px solid;
      ul {
        columns: 5;
        -webkit-columns: 5;
        -moz-columns: 5;
        list-style-type: none;
        .list-item {
            padding-top: 1em;
        }
      }
    }
    .section {
      margin-bottom: 5em;
      margin-top: 3em;
    }
    .sub-section {
      margin-bottom: 2.5em;
    }
    .header {
      margin-top: 2em;
      margin-bottom: 1em;
      font-size: $h2;
      text-transform: uppercase;
      color: black;
      font-weight: bold;
      
    }
    .sub-header {
      font-size: 28px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .body {
      margin-left: 1em;
      font-size: $h3;
      .hero-primary {
        background-color: #B6E2D3;
        border-radius: $borderRadius;
        color: black;
        padding-left: 1em;
        // border: black 4px solid;
        padding-top: 1em;
        padding-bottom: 1em;
        // box-shadow: $shadow;
        margin-bottom: 1em;
      }
      .line {
        margin-top: 0.5em;
        margin-bottom: 0.5rm;
      }
    }
}


.AboutPageMobile {
    .skillsFlex {
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-radius: $borderRadius;
      flex-wrap: wrap;
      font-size: 16px;
      background-color: #B6E2D3;
      color: black;
      border: black 2px solid;
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        list-style-type: none;
        .list-item {
            padding-top: 1em;
        }
      }
    }
    .section {
      margin-bottom: 2em;
      margin-top: 2em;
    }
    .sub-section {
      margin-bottom: 2.5em;
    }
    .header {
      margin-top: .5em;
      margin-bottom: 1em;
      font-size: $h3;
      text-transform: uppercase;
      color: black;
      font-weight: bold;
      
    }
    .sub-header {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .body {
    //   margin-left: .5em;
      font-size: $h3;
      ul,li { 
        list-style-position:inside;
        margin:0;
        padding:0; }
      .hero-primary {
        background-color: #B6E2D3;
        border-radius: $borderRadius;
        color: black;
        padding-left: 1em;
        border: black 2px solid;
        padding-top: 1em;
        padding-bottom: 1em;
        // box-shadow: $shadow;
        margin-bottom: 1em;
      }
      .line {
        margin-top: 0.5em;
        margin-bottom: 0.5rm;
        font-size: 14px;

      }
    }
}

