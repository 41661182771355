.ColoredSection {
    background-color: #ddf3eb;
    border-radius: $borderRadius;
    color: black;
    padding-left: 1em;
    font-weight: bold;
    padding-top: 2em;
    padding-bottom: 1em;
    margin-top: 2em;

}