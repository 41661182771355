.NavBar {
  height: 110px;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  align-items: center;
  .flex-wrapper {
    width: 100%;
    min-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .name {
      font-size: $h1;
      font-weight: 700;
      .position {
        font-size: $h3;
        color: $black;
        font-weight: lighter;
        margin-left: 1em;
      }
    }
    .menu {
      margin-top: 10px;
      color: $black;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      font-size: $h3;
      font-weight: bold;
    //   border-bottom: 5px solid white;
      .menuItem {
        margin-left: 2em;
        cursor: pointer;
        &:hover {
            color: black;
        }
      }
    }
  }

  .active {
    color: $blackWhole;
    border-bottom: 5px solid $primary
  }
}
