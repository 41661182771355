.fade-in {
	-webkit-animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.drop-down {
  animation: drop-down .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes drop-down {

  0% {
    transform: translate(0px, -10px);
    opacity: 0;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;

  }
  
}

.noShow {
  animation: no-show .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes no-show {

  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
  
}

.reveal {
  position: relative;
  transform: translateY(-10px);
  opacity: 0;
  transition: all .5s ease-in;
}

.reveal.active {
  transform: translateY(0px);
  opacity: 1;
}