.ImageSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 3em;
  min-height: 500px;
  .image {
      box-shadow: $shadow;
      max-height:100%;
      border-radius: 40px 10px 10px 10px;
       object-fit: contain;
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
          width: 250px;
          height: 100%;
      }
  }
  .caption {
    margin-top: 1em;
    font-size: $h3 -1;
    color: grey;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      font-size: 14px;

  }

  }
}
