//colors

$primary: #EF7C8E;
$secondary: #D8A7B1;
$black: #595959;
$blackWhole: #000000;
$white: #FFFFFF;
$lightGrey: #f5f5f5;
$green: #B6E2D3;



//fonts
$h1: 40px;
$h2: 30px;
$h3: 24px;
$body: 14px;



//borders
$borderRadius: 20px 2px 2px 2px;


//boxshadow
$shadow: 5px 5px 21px -5px rgba(0,0,0,0.25);


//outline border
$border: black 4px solid;;