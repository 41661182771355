.Ratings {
    width: 100%;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    // background-color: rgb(244, 242, 242);
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    .rating-container {
        .text {
            font-size: $h3;
            margin-right: 1em;
            color: black;
            font-weight: bold;
        }
        .icon {
            font-size: $h2 + 6;
            margin-right: 1em;
            // margin-left: 1em;
            cursor: pointer;
            color: $black;
            &:hover {
                color: black;
            }
        }
    }
}