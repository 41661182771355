@import'../../styles//variables.scss';

.LandingPage {
    height: 100vh;
    .center-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        // margin-top: -5em;
        .text {
            .hi {
                font-size: $h1;
                font-weight: bold;
                color: $primary;
                margin-bottom: 50px;
            }
            .longText {
                max-width: 473px;
                font-size: $h2;
            }
            .brand {
                margin-top: 2em;
            }
            .work {
                font-size: $h2;
                font-weight: bold; 
                color: $black;
                margin-top: 1em;
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                &:hover {
                    color: black;
                }
                .arrowIcon {
                    margin-left: .5em;
                }
            }
        }
        .shapes {
            width: 670px;
        }
    }
    
}


.LandingPage-mobile {
    padding-right: 2em;
    padding-left: 1em;
    padding-bottom: 2em;
        .text {
            .hi {
                font-size: $h2;
                font-weight: bold;
                color: $primary;
                margin-bottom: 1em;
            }
            .longText {
                font-size: $h3;
            }
            .brand {
                margin-top: 1em;
                width: 100%;
                margin: auto;
            }
            .work {
                font-size: $h3;
                font-weight: bold; 
                color: $black;
                margin-top: 1em;
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                &:hover {
                    color: black;
                }
                .arrowIcon {
                    margin-left: .5em;
                }
            }
        }
        .shapes {
            width: 100%;
            padding: 1em;
        }
    
}