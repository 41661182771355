.SafeContainer {
    // background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .container {
        max-width: 1380px;
        padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 5em;
        min-width: 300px;
        width: 100%;
        overflow: hidden;
        // background-color: white;
    }

}