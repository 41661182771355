.WorkCard {
  margin-top: 1em;
  box-shadow: 5px 5px 21px -5px rgba(0, 0, 0, 0.1);
  &:hover {
    cursor: pointer;
    transform: scale(0.9);
    box-shadow: 5px 5px 21px -5px rgba(0, 0, 0, 0.30);
  }
  .upperFrame {
    height: 400px;
    background-color: $green;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: $borderRadius;
    border-bottom: none;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      height: 350px;
      width: 350px;
    }
    .myImage {
      background-repeat: no-repeat;
      width: 300px;
    }
  }
  .lowerFrame {
    // box-shadow: $shadow;
    height: 200px;
    width: 450px;
    padding-bottom: 1em;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      width: 350px;
    }
    .header {
      padding-right: 1em;
      padding-left: 0.5em;
      padding-top: 1em;
      padding-bottom: 0.5em;
      font-size: $h3;
      font-weight: bold;
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        font-size: $h3;
      }
    }
    .tags {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 0.5em;
      margin-bottom: 1em;
      flex-wrap: wrap;
      // padding-right: 1em;
      padding-left: 1em;
      .tag-item {
        margin-right: 1em;
        background-color: $primary;
        padding: 5px;
        color: white;
        border-radius: 4px;
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
          font-size: 14px;
        }
      }
    }
    .cardBody {
      padding-right: 1em;
      padding-left: 1em;
      padding-bottom: 0.5em;
      font-size: $h3 - 1;
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        font-size: 20px;
      }
    }
    .moreButton {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      font-size: $h3;
      padding-right: 1em;
      font-weight: bold;
      color: $black;
      cursor: pointer;
      .arrowIcon {
        margin-left: 10px;
      }
      &:hover {
        color: $blackWhole;
      }
    }
  }
}
