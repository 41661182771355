.iframe-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* h/w, i.e. 1080/1920, replace with your percentage */
    max-width: 1920px; /** replace with your canvas width **/
    margin: auto;
    .responsive-iframe {
        position: absolute;
        // box-shadow: $shadow;
        top: 0;
        left: 0;
        border: 0;
        width: 100%;
        height: 100%;
        max-height: 100vh;
        max-width: calc(100vh * 1.78); /* replace with your number, divide canvas width by height, i.e. 1920w/1080h = 1.78 */
      }
}