.pills {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .item {
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 10px;
    margin-bottom: 1em;
    font-size: $h3;
    padding: .5em;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .item {
      margin-left: 1em;
      margin-right: 1em;
      border-radius: 10px;
      margin-bottom: 1em;
      font-size: $body;
      padding: .5em;
    }
  }
}

.pills-item {
  padding: 0.5em;
  width: 100%;
  margin-left: 1em;
  margin-right: 1em;
  border-radius: 10px;
  font-size: $h3;
}
