@import'../../styles//variables.scss';

.WorkPage {
    height: 100vh;
    .body {
        padding-left: 2em;
        padding-right: 2em;
        margin-top: 2em;
        display: flex;
        flex-direction: row;
        // max-height: 700px;
        // height: 700px;
        .sideMenu {
            flex-basis: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-right: 4px dotted #e3e1e1;
            color: $black;
            text-align: center;
            .navItem {
                font-size: $h3;
                margin-top: 20px;
                font-weight: bold;
                cursor: pointer;
            }

        }
        .workSample {
            flex-basis: 90%;
            overflow-y: auto;
            padding: 2em;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .card-wrapper {
                margin-right: 2em;
            }
        }
    }
    .active {
        color: $blackWhole;
        border-bottom: 5px solid $primary
      }

}

.WorkPageMobile {
    padding-left: 1em;
    padding-right: 1em;
    .splitMenu {
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        color: $black;
        .navItem {
            font-size: $h3 - 4;
            padding: .5em;
            flex-basis: 50%;
            text-align: center;
            font-weight: bold;
            border-bottom: 2px solid $lightGrey;
            border-top: 2px solid $lightGrey;
        }
    }
    .activeTab {
        color: $primary;
       
      }
      .cards-wrapper {
          margin-top: .5em;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          padding-bottom: 2em;
      }

}