.NavBarMobile {
    height: 80px;
    display: flex;
    align-items: center;
    .flex-wrapper {
      width: 100%;
      min-width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      .name {
        font-size: 20px;
        font-weight: bolder;
      }
      .menu {
        margin-top: 10px;
        color: $black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        font-size: $h3;
        font-weight: bold;
        //   border-bottom: 5px solid white;
        .menuItem {
          margin-left: 1em;
          cursor: pointer;
          &:hover {
            color: black;
          }
        }
      }
    }
  
    .active {
      color: $blackWhole;
      border-bottom: 3px solid $primary;
    }
  }
  