@import'../../styles//variables.scss';

.WorkDetailPageWeb {
    height: 100vh;
    padding-left: 1em;
    padding-right: 1em;
    .wallpaper-wrapper {
        height: 400px;
        min-height: 100px ;
        width: 100%;
        background-color: $black;
        border-radius: $borderRadius;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        overflow: hidden;
        .image {
            width: 100%;
        }
       
    }
 
    .detail-wrapper {
        margin-top: 1em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        .center-div {
            width: 1300px;
        }
        .workTitle {
            margin-top: 1em;
            text-align: left;
            font-size: $h2;
            font-weight: bold;
            margin-bottom: 1em;
            color: $primary;
        }
        .detailRead {
            .section {
                margin-top: 2em;
                margin-bottom: 2em;
            }
            .header {
                font-size: $h3;
                margin-bottom: 1em;
                font-weight: bold;
            }
            .workPara {
                font-size: $h3;
            }
        }
        .active {
            color: $blackWhole;
            border-bottom: 5px solid $primary
          }
    }
}

.WorkDetailPageMobile {
    .wallpaper-wrapper {
        width: 100%;
        height: 200px;
        overflow: hidden;
        background-color: $black;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        .image {
            width: 100%;
            height: auto;
        }
    }

    .read-wrapper {
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 2em;
    
        .title {
            font-size: 22px;
            margin-top: 1em;
            font-weight: bold;
            border-bottom: 2px solid $lightGrey;
            padding-bottom: 10px;
            margin-bottom: 2em;
            color: $primary;
            text-transform: uppercase;
        }
        .header {
            font-size: 20px;
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 1em;

        }
        .body {
            font-size: 18px;
        }
    }



}


