@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./variables.scss";

body {
  font-family: "Raleway", sans-serif;
  margin: auto;
  background-color: rgb(249, 249, 249);
}

li {
  margin-bottom: 1em;
}

.title {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: $h1;
  text-transform: uppercase;
  font-weight: bold;
  // border: $border;
  // background-color: $bla;
  padding: 0.5em;
}

.heading {
  margin-top: 3em;
  margin-bottom: 1em;
  font-size: $h1;
  text-transform: uppercase;
  font-weight: 800;
  border-bottom: 4px solid $primary;
  padding-bottom: 10px;
}

.line {
  margin-top: 0.5em;
  margin-bottom: 0.5rm;
  font-size: $h3;
  font-weight: 400;
}

.subHeading {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
}

.strong {
  font-weight: bold;
}

.primary {
  color: $primary;
}

.imageFull {
  width: 100%;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  padding-left: 2em;
  padding-right: 2em;
  justify-content: space-between;
  align-items: center;
  background-color: #F9F9F9;
  padding: 1em;
  // border-top: rgb(238, 237, 237) 2px solid;
  .icon {
    font-size: 28px;
    color:$black;

  }
  .link-tag {
    text-decoration: none;
    font-size: $h3;
    font-weight: bold;
    color:$black;
    margin-right: 1em;
    margin-left: 10px;

  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1em;
    .icon {
      font-size: 18px;
    }
    .link-tag {
      text-decoration: none;
      font-size: 18px;
    }
  }
}
